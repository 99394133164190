import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import KpiSelector from "components/ilmdb/KpiSelector.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import PropTypes from "prop-types";

import {
  fetchKPIList,
  fetchKPICategoryList,
  fetchIssueCategories,
} from "../../redux/actions/IssueActions.js";

import Datetime from "react-datetime";

import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import ReactTable from "react-table-6";

import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";

import { fetchCalls } from "redux/actions/CallActions.js";

import InteractionCallModal from "./InteractionCallModal.js";

import Utils from "../../services/utils.js";

import { useDispatch, useSelector } from "react-redux";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import RecruitersDropdown from "components/ilmdb/RecruitersDropdown.js";

import ReactExport from "react-export-excel";

import loginStore from "../../redux/stores/LoginStore";
import { getMomentLocale } from "languages/i18n/utilities/utilities.js";
import _ from "lodash";
import SuppliersPaginateDropdown from "components/Async/SuppliersPaginateDropdown.js";
let _user = loginStore.getLoginUser();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const customStyle = {
  button: {
    padding: 0,
    margin: 0,
  },
};

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class DownloadCalls extends React.Component {
  constructor(props) {
    super();
    this.state = {
      context: props.context,
      dataset: props.dataset,
    };
  }

  render() {
    return (
      <GridContainer>
        <GridItem>
          <ExcelFile
            filename={"worker_voice_cases"}
            element={
              <Button>{this.state.context.t("Download As XLS File")}</Button>
            }
          >
            <ExcelSheet data={this.state.dataset} name="calls">
              <ExcelColumn label="id" value="id" />
              <ExcelColumn label="client_nickname" value="client_nickname" />
              <ExcelColumn label="supplier_name" value="supplier_name" />
              <ExcelColumn
                label="source_recruiter_name"
                value="source_recruiter_name"
              />
              <ExcelColumn
                label="destination_recruiter"
                value="destination_recruiter"
              />
              <ExcelColumn label="kpi_long" value="kpi_long" />
              <ExcelColumn label="kpi" value="kpi" />
              <ExcelColumn label="kpi_count" value="kpi_count" />
              <ExcelColumn label="kpi_level" value="kpi_level" />
              <ExcelColumn
                label="issue_category_name"
                value="issue_category_name"
              />
              <ExcelColumn label="description_long" value="description_long" />
              <ExcelColumn label="issara_staff" value="issara_staff" />
              <ExcelColumn label="created" value="created" />
            </ExcelSheet>
          </ExcelFile>
        </GridItem>
      </GridContainer>
    );
  }
}

export default function InteractionCallList(props, context) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const [allCallsAfterSearchDate, setAllCallsAfterSearchDate] = useState(
    undefined
  );
  const [allCallsBeforeSearchDate, setAllCallsBeforeSearchDate] = useState(
    undefined
  );
  const [supplierIds, setSupplierIds] = useState([]);
  const [srcRecruiterIds, setSrcRecruiterIds] = useState([]);
  const [destRecruiterIds, setDestRecruiterIds] = useState([]);
  const kpis = useSelector((state) => state.kpisReducer.kpiitems);
  const fetchingKPIs = useSelector((state) => state.kpisReducer.fetchingKPIs);
  const fetchedKPIs = useSelector((state) => state.kpisReducer.fetchedKPIs);
  const lang = useSelector((state) => state.i18nState.lang);

  const [runCallServerSearch, setRunCallServerSearch] = useState(true);

  const currentUser = loginStore.getLoginUser();

  const classes = useStyles();

  const [modal, setModal] = useState({
    open: false,
    value: null,
  });

  const [workerVoiceCaseCalls, setWorkerVoiceCaseCalls] = useState(undefined);
  const workerVoiceCaseCallsSearch = useSelector((state) => {
    return state.workerVoiceCaseCallsReducer.items;
  });
  const fetchingCalls = useSelector((state) => {
    return state.workerVoiceCaseCallsReducer.fetchingCalls;
  });

  const [origSearchData, setOrigSearchData] = useState([]);
  const [reactTableData, setReactTableData] = useState({
    data: undefined,
    filter: false,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(false);

  const setReactTable = (reactTable) => {
    setReactTableData(reactTable);
    if (props.onReactTable) {
      props.onReactTable({
        ...reactTable,
        suppliers: supplierIds,
        srcRecruiters: srcRecruiterIds,
        destRecruiter: destRecruiterIds,
      });
    }
  };

  const handlePageChange = (newPage) => {
    let url = null;
    if (newPage === page) {
      setPage(newPage + 1);
      url = next;
    } else if (newPage < page) {
      setPage(page - 1);
      url = previous;
    } else if (newPage > page) {
      url = undefined;
    }
    if (url) {
      setLoading(true);
      axios
        .get(url)
        .then((response) => {
          let tableData = generateTableData(response.data);
          setReactTable({
            data: tableData,
            filter: false,
          });
          setNext(response.data.next);
          setPrevious(response.data.previous);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching paginated data. ", error);
          setLoading(false);
        });
    }
  };

  const generateTableData = (data) => {
    const tableData = [];
    data.results.forEach((caseIssueRow) => {
      const simpleButtons_external_edit = (
        <div>
          <a href={"/admin/new-call?id=" + caseIssueRow.id} target="__blank">
            <LaunchIcon />
          </a>
        </div>
      );

      const simpleButtons_edit = (
        <div>
          <Button
            title={context.t("Edit Item:") + caseIssueRow.id}
            color={"success"}
            icon={Edit}
            simple
            className={classes.button}
            key={Utils.giveMeGuid()}
            onClick={() => {
              props.history.push(`/admin/new-call?id=${caseIssueRow.id}`);
            }}
          >
            <Edit />
          </Button>
        </div>
      );

      const simpleButtons_view = (
        <div>
          <Button
            title={context.t("View Item:") + caseIssueRow.id}
            simple
            color={"info"}
            className={classes.button}
            key={Utils.giveMeGuid()}
            onClick={() => {
              setModal({
                open: true,
                value: Object.assign({}, caseIssueRow),
              });
            }}
          >
            <VisibilityIcon />
          </Button>
        </div>
      );

      let subComponentView = (
        <Card key={caseIssueRow.id}>
          <CardBody>
            {caseIssueRow.kpi_ids &&
            caseIssueRow.kpi_ids !== null &&
            caseIssueRow.kpi_ids.length > 0 &&
            kpis !== undefined &&
            kpis !== null &&
            kpis.length > 0 ? (
              (() => {
                let foundKpis = new Array();
                kpis.forEach((kpi) => {
                  if (caseIssueRow.kpi_ids.includes(kpi.id)) {
                    foundKpis.push(kpi);
                  }
                });

                return (
                  <KpiSelector
                    issueCategory={1}
                    selectedKpis={(() => {
                      let selectedKpis = {};

                      foundKpis.forEach((kpi) => {
                        if (
                          selectedKpis[kpi.kpi_category.id] === undefined ||
                          selectedKpis[kpi.kpi_category.id] === null
                        ) {
                          selectedKpis[kpi.kpi_category.id] = new Array();
                        }
                        selectedKpis[kpi.kpi_category.id].push(kpi.id);
                      });
                      return selectedKpis;
                    })()}
                    displayOnlyMode={true}
                  />
                );
              })()
            ) : (
              <div>
                {context.t("No KPIs logged for this call id:")}
                {caseIssueRow.id}
              </div>
            )}
          </CardBody>
        </Card>
      );

      tableData.push({
        ...caseIssueRow,
        client_nickname: ["", null, undefined].includes(
          caseIssueRow.client_nickname
        )
          ? "Anonymous"
          : caseIssueRow.client_nickname,
        kpi_count:
          caseIssueRow.kpi_ids !== undefined && caseIssueRow.kpi_ids !== null
            ? caseIssueRow.kpi_ids.length
            : 0,
        actions_edit: simpleButtons_edit,
        actions_view: simpleButtons_view,
        actions_edit_external: simpleButtons_external_edit,
        subComponent: subComponentView,
      });
    });
    return tableData;
  };

  const debouncedSearch = useCallback(
    _.debounce(async (searchQuery, filter = null) => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/cases_lean?lang=${lang}&q=${searchQuery}&filter_by=${
            filter ? filter : ""
          }`
        );
        let tableData = generateTableData(response.data);
        setReactTable({
          data: tableData,
          filter: false,
        });
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / pageSize));
        setPage(1);
      } catch (error) {
        console.log("error", error);
      }
    }, 2000),
    []
  );

  const handleFilterChange = (filtered, row, value) => {
    if (value.length > 1) {
      debouncedSearch(value, filtered[0].id);
    } else if (value.length === 0) {
      setReactTable({
        data: origSearchData,
        filter: false,
      });
    }
  };

  const setupWorkerVoiceCalls = async () => {
    if (
      kpis !== undefined &&
      kpis.length > 0 &&
      workerVoiceCaseCalls !== undefined &&
      reactTableData.data === undefined
    ) {
      setNext(workerVoiceCaseCalls.next);
      setPrevious(workerVoiceCaseCalls.previous);
      setTotalPages(Math.ceil(workerVoiceCaseCalls.count / pageSize));

      let tableData = generateTableData(workerVoiceCaseCalls);

      setOrigSearchData(tableData);

      setPage(1);

      setReactTable({
        data: tableData,
        filter: false,
      });
    }

    if (
      search !== undefined &&
      search !== undefined &&
      search.length > 1 &&
      reactTableData.filter != false &&
      reactTableData.data !== undefined
    ) {
      await debouncedSearch(search);
    }
  };

  useEffect(() => {
    dispatch(fetchKPIList());
    dispatch(fetchKPICategoryList());
    dispatch(fetchIssueCategories());
  }, []);

  useEffect(() => {
    if (runCallServerSearch) {
      dispatch(
        fetchCalls(
          allCallsAfterSearchDate,
          allCallsBeforeSearchDate,
          supplierIds,
          srcRecruiterIds,
          destRecruiterIds
        )
      );
    } else {
      setWorkerVoiceCaseCalls(workerVoiceCaseCallsSearch);
    }

    setRunCallServerSearch(false);
  }, [workerVoiceCaseCallsSearch, runCallServerSearch, kpis]);
  setupWorkerVoiceCalls();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} lg={12}>
        <InteractionCallModal
          key={Utils.giveMeGuid()}
          open={modal.open}
          value={modal.value ? modal.value.id : undefined}
          onClose={() => setModal({ value: undefined, open: false })}
        />
      </GridItem>

      <GridItem xs={12} sm={12} lg={12}>
        <Card style={{ marginTop: 0 }}>
          <CardHeader>
            <h4>{context.t("Worker Voice Calls")}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} lg={6}>
                <SuppliersPaginateDropdown
                  label={context.t("Suppliers")}
                  placeholder={context.t("Select Suppliers")}
                  isClearable={true}
                  value={supplierIds}
                  isSearchable={true}
                  isMulti={true}
                  onSelect={(supplierIds) => {
                    setSupplierIds(supplierIds);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} lg={6}>
                <RecruitersDropdown
                  multipleselect={true}
                  value={srcRecruiterIds}
                  label={context.t("Source Recruiter")}
                  onSelect={(trecruiterIds) => {
                    setSrcRecruiterIds(trecruiterIds);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} lg={6}>
                <RecruitersDropdown
                  multipleselect={true}
                  value={destRecruiterIds}
                  label={context.t("Destination Recruiter")}
                  onSelect={(trecruiterIds) => {
                    setDestRecruiterIds(trecruiterIds);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} lg={6}>
                <GridContainer>
                  <GridItem>
                    <InputLabel>{context.t("After Date:")}</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        locale={getMomentLocale(lang)}
                        timeFormat={false}
                        inputProps={{ placeholder: context.t("Date") }}
                        closeOnSelect={true}
                        value={allCallsAfterSearchDate}
                        onChange={(e) => {
                          setAllCallsAfterSearchDate(
                            e === undefined || e === "" || e === null
                              ? undefined
                              : new Date(e)
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <InputLabel>{context.t("Before Date:")}</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        locale={getMomentLocale(lang)}
                        timeFormat={false}
                        inputProps={{ placeholder: context.t("Date") }}
                        closeOnSelect={true}
                        value={allCallsBeforeSearchDate}
                        onChange={(e) => {
                          setAllCallsBeforeSearchDate(
                            e === undefined || e === "" || e === null
                              ? undefined
                              : new Date(e)
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} lg={6}>
                <Button
                  onClick={(e) => {
                    setWorkerVoiceCaseCalls(undefined);
                    setReactTable({
                      data: undefined,
                      filter: false,
                    });
                    setRunCallServerSearch(true);
                  }}
                >
                  {context.t("Search")}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      {loginStore.isIssaraManagement() && (
        <GridItem>
          {reactTableData !== undefined && reactTableData.data !== undefined ? (
            <DownloadCalls dataset={reactTableData.data} context={context} />
          ) : null}
        </GridItem>
      )}

      <GridItem xs={12} sm={12} lg={12}>
        <Card style={{ marginTop: 0 }}>
          <CardHeader>
            <h4>
              {context.t("Search Results")} ({context.t("Found")}-
              {reactTableData !== undefined &&
              reactTableData.data !== undefined &&
              reactTableData.data.length !== undefined
                ? totalPages * pageSize
                : 0}
              )
            </h4>
          </CardHeader>
          <CardBody>
            <div>
              <GridItem xs={12} sm={12} lg={12}>
                <FormControl fullWidth>
                  <CustomInput
                    id={"search_text"}
                    labelText={context.t("Search")}
                    isTextArea={false}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={search}
                    inputProps={{
                      onChange: (e) => {
                        setSearch(e.target.value);

                        if (
                          e.target.value === undefined ||
                          e.target.value === ""
                        ) {
                          setReactTable({
                            data: undefined,
                            filter: false,
                          });
                        } else {
                          let newReact = Object.assign({}, reactTableData);
                          newReact.filter = true;
                          setReactTable(newReact);
                        }
                      },
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} lg={12}>
                {fetchingKPIs === true && !fetchedKPIs ? (
                  <div>{context.t("Fetching...")}</div>
                ) : (
                  <ReactTable
                    manual
                    page={page - 1}
                    pages={totalPages}
                    defaultPageSize={10}
                    filterable={true}
                    onFilteredChange={handleFilterChange}
                    onPageChange={handlePageChange}
                    showPageSizeOptions={false}
                    showPageJump={false}
                    className="-striped -highlight"
                    defaultFilterMethod={(filter, row) =>
                      Utils.findStringInObjectFields(
                        [row._original],
                        filter.value,
                        [filter.id]
                      ).length > 0
                    }
                    data={reactTableData.data}
                    loading={fetchingCalls || loading}
                    collapseOnDataChange={false}
                    SubComponent={(d) => {
                      let caseIssue = d.original;
                      return caseIssue.subComponent;
                    }}
                    columns={[
                      {
                        Header: context.t("Id"),
                        accessor: "id",
                        width: 80,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Supplier"),
                        accessor: "supplier_name",
                        width: 180,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("RecruiterSrc"),
                        accessor: "source_recruiter_name",
                        width: 120,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Level"),
                        accessor: "kpi_level",
                        width: 80,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Issue Category"),
                        accessor: "issue_category_name",
                        Cell: (props) => (
                          <HtmlTooltip title={props.value} interactive>
                            <div className="cell-overflow">
                              {Utils.shortenString(props.value, 25)}
                            </div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Kpi"),
                        accessor: "translated_kpis",
                        width: 160,
                        Cell: (props) => {
                          const kpiDescriptions = props.value
                            ? `<ul>${props.value
                                .map((kpi) => `<li>${kpi.description}</li>`)
                                .join("")}</ul>`
                            : "";

                          return (
                            <HtmlTooltip
                              title={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: kpiDescriptions,
                                  }}
                                />
                              }
                              interactive
                            >
                              <div className="cell-overflow">
                                {Utils.shortenString(
                                  props.value
                                    .map((kpi) => kpi.description)
                                    .join(", "),
                                  25
                                )}
                              </div>
                            </HtmlTooltip>
                          );
                        },
                      },
                      {
                        Header: context.t("# Kpis"),
                        accessor: "kpi_count",
                        width: 90,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Description"),
                        accessor: "description",
                        width: 100,
                        Cell: (props) => {
                          return (
                            <HtmlTooltip
                              title={props.value ? props.value : ""}
                              interactive
                            >
                              <div className="cell-overflow">
                                {Utils.shortenString(props.value, 25)}
                              </div>
                            </HtmlTooltip>
                          );
                        },
                      },
                      {
                        Header:
                          _user && _user.groups.includes("CSO")
                            ? context.t("Staff")
                            : context.t("IssaraStaff"),
                        accessor: "issara_user",
                        width: 100,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Client"),
                        accessor: "client_nickname",
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Gender"),
                        accessor: "client_gender_name",
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Nationality"),
                        accessor: "client_nationality_name",
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Origin Country Province"),
                        accessor: "client_origin_province",
                        show: currentUser.groups.includes("Issara Staff")
                          ? true
                          : false,
                        width: 120,
                        Cell: (props) => {
                          return props.original.client_origin_province
                            ? props.original.client_origin_province
                            : "";
                        },
                      },
                      {
                        Header: context.t("Incoming Outgoing"),
                        accessor: "interaction_type",
                        width: 100,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Phone"),
                        accessor: "client_phonenumber",
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: context.t("Interaction"),
                        accessor: "interaction_date",
                        width: 90,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: "",
                        accessor: "actions_view",
                        width: 30,
                        sortable: false,
                        filterable: false,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: "",
                        accessor: "actions_edit",
                        width: 30,
                        sortable: false,
                        filterable: false,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: "",
                        accessor: "actions_edit_external",
                        width: 30,
                        sortable: false,
                        filterable: false,
                        Cell: (props) => (
                          <HtmlTooltip
                            title={props.value ? props.value : ""}
                            interactive
                          >
                            <div className="cell-overflow">{props.value}</div>
                          </HtmlTooltip>
                        ),
                      },
                      {
                        Header: "",
                        width: 20,
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                  />
                )}
              </GridItem>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

InteractionCallList.contextTypes = {
  t: PropTypes.func.isRequired,
};
