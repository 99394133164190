import moment from "moment-timezone";
// import burmese from "moment/locale/my";
// import khmer from "moment/locale/km";
import english from "moment/locale/en-gb";
// import thai from "moment/locale/th";
// import nepali from "moment/locale/ne";
// import japanese from "moment/locale/ja";

export const updateMomentLocale = (lang) => {
  // set English locale for developers
  if (process.env.REACT_APP_DISABLE_TRANSLATIONS === "true") {
    moment.tz.setDefault("Asia/Bangkok");
    moment.updateLocale("en", english);
    return;
  }

  // "Note: Currently, I have disabled the locale for Calendar date timeDay
  // because the server can't accept multiple language date-time values,
  // and I am concerned that the filtering by date might not work properly.
  // Therefore, I have set the English Gregorian calendar as the default standard locale to ensure that filtering and insertion work smoothly."

  if (lang === "mm") {
    moment.tz.setDefault("Asia/Yangon");
    moment.updateLocale("en", english);
    // moment.updateLocale("my", burmese);
  } else if (lang === "kh") {
    moment.tz.setDefault("Asia/Phnom_Penh");
    moment.updateLocale("en", english);
    // moment.updateLocale("km", khmer);
  } else if (lang === "th") {
    moment.tz.setDefault("Asia/Bangkok");
    // moment.updateLocale("th", thai);
    moment.updateLocale("en", english);
    document.body.style.fontFamily = "Sarabun";
  } else if (lang === "ne") {
    moment.tz.setDefault("Asia/Kathmandu");
    // moment.updateLocale("ne", nepali);
    moment.updateLocale("en", english);
  } else if (lang === "ja") {
    moment.tz.setDefault("Asia/Tokyo");
    // moment.updateLocale("ja", japanese);
    moment.updateLocale("en", english);
  } else {
    moment.tz.setDefault("Asia/Bangkok");
    moment.updateLocale("en", english);
  }
};

export const getMomentLocale = (lang) => {
  const localeMap = {
    ja: "ja",
    kh: "km",
    mm: "my",
    ne: "ne",
    th: "th",
  };

  return localeMap[lang] || "en";
};

// set the default lang according to local storage
export const setMomentLocaleFromLocalStorage = (userType) => {
  if (userType === "cso") {
    const cso_lang = localStorage.getItem("cso_lang") || "en";
    updateMomentLocale(cso_lang);
  }

  if (userType === "supplier") {
    const supplier_lang = localStorage.getItem("supplier_lang") || "en";
    updateMomentLocale(supplier_lang);
  }

  if (userType === "global_partner") {
    const global_partner_lang =
      localStorage.getItem("global_partner_lang") || "en";
    updateMomentLocale(global_partner_lang);
  }
};
