import React, { Fragment, useEffect, useState, useRef } from "react";
import { setLanguage } from "redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
// import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
// import login store to check user type
import loginStore from "redux/stores/LoginStore";
// import langauge change dialog
import LanguageChangeDialog from "./LanguageChangeDialog";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.js";

// ILM components
import * as LoginActions from "../../redux/actions/LoginActions";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import customStyles from "./AdminNavbarLinks.module.css";
import { updateMomentLocale } from "languages/i18n/utilities/utilities";

const useStyles = makeStyles(styles);
const us_flag = require("assets/img/flags/US.png");
const ne_flag = require("assets/img/flags/NE.png");
const th_flag = require("assets/img/flags/TH.png");
const mm_flag = require("assets/img/flags/MM.png");
const ja_flag = require("assets/img/flags/JA.png");
const kh_flag = require("assets/img/flags/KH.png");
const id_flag = require("assets/img/flags/ID.png");
const bn_flag = require("assets/img/flags/BN.png");

export default function HeaderLinks(props, context) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(false);
  const lang = useSelector((state) => state.i18nState.lang);
  const [tochangeLang, setTochangeLang] = useState(null);
  // const [hideAll, setHideAll] = useState(false);
  const [alert, setAlert] = useState(null);

  const [openProfile, setOpenProfile] = useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    LoginActions.logout();
  };

  useEffect(() => {
    if (loginStore.isSupplier()) {
      updateMomentLocale(
        localStorage.getItem("supplier_lang")
          ? localStorage.getItem("supplier_lang")
          : "en"
      );
    } else if (loginStore.isCSO()) {
      updateMomentLocale(
        localStorage.getItem("cso_lang")
          ? localStorage.getItem("cso_lang")
          : "en"
      );
    } else if (loginStore.isGlobalPartner()) {
      updateMomentLocale(
        localStorage.getItem("global_partner_lang")
          ? localStorage.getItem("global_partner_lang")
          : "en"
      );
    } else {
      updateMomentLocale("en");
    }
  }, []);

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  // start from here
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleLanguageChange(lang) {
    setDialogState(true);
    setTochangeLang(lang);
  }
  // language text renderer
  const languageTextRenderer = (lang) => {
    switch (lang) {
      case "en":
        return (
          <>
            <img src={us_flag} fontSize="small" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("English")}
          </>
        );
      case "ne":
        return (
          <>
            <img src={ne_flag} fontSize="small" height="14px" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Nepali")}
          </>
        );
      case "bn":
        return (
          <>
            <img src={bn_flag} fontSize="small" height="14px" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Bengali")}
          </>
        );
      case "mm":
        return (
          <>
            <img src={mm_flag} fontSize="small" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Burmese")}
          </>
        );
      case "th":
        return (
          <>
            <img src={th_flag} fontSize="small" height="14px" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Thai")}
          </>
        );
      case "ja":
        return (
          <>
            <img src={ja_flag} fontSize="small" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Japanese")}
          </>
        );
      case "kh":
        return (
          <>
            <img src={kh_flag} fontSize="small" height="14px" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Khmer")}
          </>
        );
      case "id":
        return (
          <>
            <img src={id_flag} fontSize="small" height="14px" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("Indonesian")}
          </>
        );
      default:
        return (
          <>
            <img src={us_flag} fontSize="small" />
            &nbsp;&nbsp;&nbsp;&nbsp;{context.t("English")}
          </>
        );
    }
  };

  // confirmation message
  const languageConfirmation = (lang) => {
    if (lang) {
      updateMomentLocale(lang);
      dispatch(setLanguage(lang));
      if (loginStore.isSupplier()) {
        localStorage.setItem("supplier_lang", lang);
      } else if (loginStore.isCSO()) {
        localStorage.setItem("cso_lang", lang);
      } else if (loginStore.isGlobalPartner) {
        localStorage.setItem("global_partner_lang", lang);
      }
    }
    setOpen(false);
    setDialogState(false);
    window.location.href = window.location.href;
  };

  return alert ? (
    <div>{alert}</div>
  ) : (
    <div className={wrapper}>
      {/* language change confirmation Dialog box */}
      <LanguageChangeDialog
        tochangeLang={tochangeLang}
        fullToChangeLang={languageTextRenderer(tochangeLang)}
        confirmation={(lang) => languageConfirmation(lang)}
        dialogState={dialogState}
      />
      <div className={managerClasses}>
        {/* multiple language drop down section */}
        {loginStore.isSupplier() ||
        loginStore.isCSO() ||
        loginStore.isGlobalPartner() ? (
          <Button
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ width: "160px" }}
          >
            {languageTextRenderer(lang)}
          </Button>
        ) : (
          ""
        )}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleLanguageChange.bind(this, "en")}>
                      <ListItemIcon className={customStyles.flags}>
                        <img src={us_flag} fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {context.t("English")}
                      </Typography>
                      &nbsp;&nbsp;
                    </MenuItem>
                    {loginStore.isGlobalPartner() && (
                      <MenuItem onClick={handleLanguageChange.bind(this, "ja")}>
                        <ListItemIcon className={customStyles.flags}>
                          <img src={ja_flag} fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {context.t("Japanese")}
                        </Typography>
                      </MenuItem>
                    )}
                    {loginStore.isSupplier() && (
                      <MenuItem onClick={handleLanguageChange.bind(this, "th")}>
                        <ListItemIcon className={customStyles.flags}>
                          <img src={th_flag} fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {context.t("Thai")}
                        </Typography>
                      </MenuItem>
                    )}
                    {loginStore.isCSO() && (
                      <Fragment>
                        <MenuItem
                          onClick={handleLanguageChange.bind(this, "mm")}
                        >
                          <ListItemIcon className={customStyles.flags}>
                            <img src={mm_flag} fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {context.t("Burmese")}
                          </Typography>
                          &nbsp;&nbsp;
                        </MenuItem>
                        <MenuItem
                          onClick={handleLanguageChange.bind(this, "kh")}
                        >
                          <ListItemIcon className={customStyles.flags}>
                            <img src={kh_flag} fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {context.t("Khmer")}
                          </Typography>
                          &nbsp;&nbsp;
                        </MenuItem>
                        <MenuItem
                          onClick={handleLanguageChange.bind(this, "id")}
                        >
                          <ListItemIcon className={customStyles.flags}>
                            <img src={id_flag} fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {context.t("Indonesian")}
                          </Typography>
                          &nbsp;&nbsp;
                        </MenuItem>
                        <MenuItem
                          onClick={handleLanguageChange.bind(this, "ne")}
                        >
                          <ListItemIcon className={customStyles.flags}>
                            <img src={ne_flag} fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {context.t("Nepali")}
                          </Typography>
                          &nbsp;&nbsp;
                        </MenuItem>
                        <MenuItem
                          onClick={handleLanguageChange.bind(this, "th")}
                        >
                          <ListItemIcon className={customStyles.flags}>
                            <img src={th_flag} fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {context.t("Thai")}
                          </Typography>
                          &nbsp;&nbsp;
                        </MenuItem>
                        {/* <MenuItem
                          onClick={handleLanguageChange.bind(this, "bn")}
                        >
                          <ListItemIcon className={customStyles.flags}>
                            <img src={bn_flag} fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {context.t("Bengali")}
                          </Typography>
                          &nbsp;&nbsp;
                        </MenuItem> */}
                      </Fragment>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        {/* logout button section */}
        <Button
          color="transparent"
          aria-label={context.t("Person")}
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : context.t("Profile")}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleLogout}
                      className={dropdownItem + props.sidebarWrapper}
                    >
                      <div className={classes.dropdownItem}>
                        {rtlActive ? "الخروج" : context.t("Log out")}
                      </div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};

HeaderLinks.contextTypes = {
  t: PropTypes.func.isRequired,
};
